<template>
  <router-link
    :to="
      loginUser.role_id === 1
        ? `/service/edit/${provideServices.slug}`
        : `/service/${provideServices.slug}`
    "
  >
    <div class="provideServices">
      <div class="ml-10">
        <div class="provideServices__title">{{ provideServices.name }}</div>
        <div class="provideServices__text">
          <span v-html="provideServices.short_description"></span>
        </div>
      </div>
      <div class="provideServices__image" :style="serviceBackgroundImageStyle">
        <experts-portrait :experts="provideServices.experts"
          >experts
        </experts-portrait>
        <div class="provideServices__link">
          learn more <span><arrow /></span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImageService from "@/components/UI/images/ImageService";
import ExpertsPortrait from "@/components/elements/ExpertsPortrait";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import { mapGetters } from "vuex";

export default {
  props: {
    provideServices: Object,
  },
  name: "ProvideServices",
  components: { Arrow, ArrowThink, BaseButton, ExpertsPortrait, ImageService },
  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
    };
  },
  methods: {
    checkWebp() {
      try {
        return (
          document
            .createElement("canvas")
            .toDataURL("image/webp")
            .indexOf("data:image/webp") === 0
        );
      } catch (err) {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    serviceBackgroundImageStyle() {
      let format = this.checkWebp() ? "webp" : "png";
      return `background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.path}/services/${this.provideServices.slug}/${this.provideServices.image}-500.${format}) center / cover`;
    },
  },
};
</script>

<style></style>
