<template>
  <main-layout>
    <div class="row justify-content-between">
      <h1 v-if="loginUser.role_id !== 1" class="w-auto">
        {{ $t("servicesPageTitle") }}
      </h1>
      <h1 v-else class="w-auto">{{ $t("servicesAdminPageTitle") }}</h1>
      <div class="servicesPage__searchInput">
        <search-input
          v-model="search"
          :search-function="searchService"
          @keyup.enter="searchService"
          :placeholder="$t('servicesPageSearch')"
        />
      </div>
    </div>
    <div v-if="services.length > 0" class="row">
      <div
        v-for="service in services"
        :key="service.id"
        class="col-12 col-lg-6"
      >
        <provide-services :provide-services="service" />
      </div>
    </div>
    <div v-else class="mt-30">
      <h5>{{ $t("noFoundResult") }}</h5>
      <p class="mt-10">{{ $t("tryAnother") }}</p>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SearchInput from "@/components/UI/inputs/SearchInput";
import ProvideServices from "@/components/elements/card/ProvideServices";
import { mapGetters } from "vuex";

export default {
  name: "ServicesPage",
  components: { ProvideServices, SearchInput, MainLayout },
  data() {
    return {
      services: [],
      search: "",
    };
  },
  mounted() {
    this.getServices();
  },
  methods: {
    getServices(searchFilter) {
      let search = searchFilter ? searchFilter : null;
      this.$http
        .get("services", {
          params: {
            search: search,
          },
        })
        .then(({ data }) => {
          this.services = data.body;
        });
    },
    searchService() {
      this.getServices(this.search);
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
