<template>
  <div v-if="experts" class="d-flex align-items-center">
    <div class="me-2 expertsPortrait">
      <div v-for="(expert, i) in experts">
        <avatar
          class="expertsPortrait__item"
          v-if="i < 3"
          user-role="experts"
          size="100"
          :user-code="expert.code"
          :image-name="expert.image"
          :experts="true"
          height="30px"
          width="30px"
        />
      </div>
    </div>
    {{ experts.length }}
    <slot></slot>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";

export default {
  props: {
    experts: Array,
  },
  name: "ExpertsPortrait",
  components: { Avatar },
};
</script>

<style></style>
